<template>
  <v-menu
    ref="menu"
    v-model="menu" 
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :label="control.label"
        :value="displayDate"
        :disabled="control.disabled"
        :error-messages="control.errors"
        readonly
        v-on="on"
        class="j-input"
      />
    </template>
    <v-date-picker
      v-model="fieldDate"
      no-title
      scrollable
      @input="menu = false"
    />
  </v-menu>
</template>

<script>

// Mixin
import Control from './Mixin'

// Helpers
import moment from 'moment'

export default {
  name: 'Date',
  mixins: [ Control ],
  data() {
    return {
      menu: false,
      dateValue: null
    }
  },
  computed: {
    displayDate() {
      const value = this.control.get()
      return value ? moment(value).format('ddd, MMMM D') : ''
    },
    fieldDate: {
      get() {
        return this.dateValue
      },
      set(newDate) {
        // Get year, month, date
        const yy = newDate.split('-')[0]
        const mm = parseInt(newDate.split('-')[1]) - 1 // moment months are zero-indexed
        const dd = newDate.split('-')[2]
        this.control.set(moment().year(yy).month(mm).date(dd).format())
        this.dateValue = newDate
      }
    }
  },
  methods: {
    handleClear () {
      // Also clear the time and date
      this.dateValue = ''
      // this.model[this.field] = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.v-label {
  font-weight: bold;
}
</style>